// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;
import * as moment from 'moment';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { switchMap, debounceTime } from 'rxjs/operators';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Custom services/components
import { LoginService } from "../../authentication/authentication-service";
import { SpotResource } from '../resources/spot-resource';
import { AgencyResource } from '../../communication/resources/agency-resource';
import { AdvertiserResource } from '../../communication/resources/advertiser-resource';
import { BrandResource } from '../../communication/resources/brand-resource-factory';
import { BulkUploadService } from '../../communication/bulkUpload-service';
import { CtsBulkUploadCompleteDialog } from '../cts-bulk-upload-complete-dialog/cts-bulk-upload-complete-dialog.component';
import { io } from 'socket.io-client';
import { EndPointService } from '../endpoint-service';
import { HttpClient } from '@angular/common/http';
import { KeyValue } from '@angular/common';

export interface spotData {
    isci: string;
};

export interface ingestState {
    spotId: number;
    ingestStatus: string;
    ingestPercent: number;
}
export interface DialogData {
    spots: spotData[];
}

interface SpotMetadata {
    isci: string;
    title?: string;
    agencyId?: number;
    advertiserId?: number;
    brandId?: number;
    file: File
    invalid?: string|null;
    isExisting?: boolean;
    status?: string|null;
    id?: number;
    progress?: string | number;
    currentStatus?: string;
    hasMedia?: boolean;
    invalidFileFormat?: boolean;
    invalidFileName?: boolean;
}

interface creationHash {
    [index: string]: SpotMetadata
}

@Component({
    selector: 'cts-bulk-upload-dialog',
    template: require('./cts-bulk-upload-dialog.component.html'),
})
export class CtsBulkUploadDialog implements OnInit{
    public spotsToCreate: creationHash = {};
    public dropped: Boolean = false;
    public checking: Boolean = false;
    public conflicts: string[] = [];
    public newSpotRows:FormGroup = new FormGroup({});
    public filteredAgencies: {[index: string]: any[]} = {};
    public filteredAdvertisers: {[index: string]: any[]} = {};
    public filteredBrands: {[index: string]: any[]} = {};
    public strictIsci: Boolean;
    public sessionData: any;
    public isFriend: boolean = false;
    public hasPermission: any;
    bulkUploadService: any;
    constructedFormGroup:any = {};
    uploadButtonText: string = 'Start Uploading';
    showRemoveSpot: boolean = true;
    showUploadingMessage : boolean = false;
    showProgress: boolean = false;
    socket:any;
    isciChange: string = '';
    constructor(
        public dialogRef: MatDialogRef<CtsBulkUploadDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private spotResource: SpotResource,
        private loginService: LoginService,
        private agencyResource: AgencyResource,
        private advertiserResource: AdvertiserResource,
        private brandResource: BrandResource,
        public dialog: MatDialog,
        private injector: Injector,
        private endPointService: EndPointService,
        private http: HttpClient
    ) {
        this.strictIsci = this.loginService.getSessionData().account.strictIsci;
        this.sessionData = this.loginService.getSessionData();
        this.hasPermission = this.loginService.hasPermission;
        this.bulkUploadService = this.injector.get(BulkUploadService);
        const socketConnection: string = sessionStorage.getItem('socketUrl') as string;
        if(socketConnection) {
            this.socketConnection(socketConnection);
        } else {
            this.http.get('/socketConfig').subscribe((res) => {
                const url = (res + this.endPointService.socketEndpoint).toString();
                sessionStorage.setItem('socketUrl', url);
                this.socketConnection(url);
            });
        }
    }
    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
    }
    socketConnection(url:string) {
        //socket connect needs websocket when connecting to 3rd party domain
        //if its local/same origin just io(url) is enough.
        this.socket = io(url,  { transports: [ "websocket" ] }  );
        this.socket.on('Spot_Ingest_Progress', (ingestRes:any) => {
            let ingestUpdate:ingestState = ingestRes;
            const ingestState: string = ingestUpdate.ingestStatus.toUpperCase();
            Object.keys(this.spotsToCreate).forEach((index) => {
                if(this.spotsToCreate[index].id === ingestUpdate.spotId){
                    this.spotsToCreate[index].currentStatus = '';
                    if(ingestState == 'VERIFYING' || ingestState == 'PROCESSING'){
                        this.spotsToCreate[index].currentStatus = ingestState;
                    }
                    this.spotsToCreate[index].progress = ingestUpdate.ingestPercent;
                    if(ingestUpdate.ingestPercent === 100) {
                        this.verifySpotIngestCompleted();
                        this.spotsToCreate[index].status = this.displayStatus(ingestUpdate.ingestStatus);
                    }
                }
            });
        });
    }
    //Method to verify ingest process is completed for all spot uploaded with bulk upload
    verifySpotIngestCompleted() {
        const result =  Object.keys(this.spotsToCreate).filter((eachSpot:any) => 
                             this.spotsToCreate[eachSpot].progress === 100
                         );
        if(result.length === Object.keys(this.spotsToCreate).length){
             this.uploadButtonText = 'Uploaded';
             const completeDialog = this.dialog.open(CtsBulkUploadCompleteDialog, {
                 disableClose: true,
                 width: '450px',
                 panelClass: 'cts-bulk-upload-complete-dialog',
             });
             completeDialog.afterClosed().subscribe(() => {
                 this.dialogRef.close();
                 window.location.reload();
             });
        }
     }
    ngOnInit(): void {
        //Show uploading message when user choose the option view progress
        this.bulkUploadService.showUploadingMessage$.subscribe((data:boolean) => {
            if(data) {
                this.showUploadingMessage = true;
            }
        });
        //update the spots id once spots are starting uploading in bulk upload
        this.bulkUploadService.sendSpotDetails$.subscribe((data:any) => {
            if(data){
                Object.keys(this.spotsToCreate).forEach((index) =>{
                    if(this.spotsToCreate[index].isci === data.isci){
                        this.spotsToCreate[index].id = data.id;
                    }
                });
            }
        })
    }
    //Method to show options dialog once user start clicking upload on bulk upload modal
    openOptionsDialog(spots:any) {
        Object.keys(this.spotsToCreate).forEach((index) => { 
            this.spotsToCreate[index].progress = 0;
            this.spotsToCreate[index].currentStatus = 'uploading';
        });
        this.showRemoveSpot = false;
        this.showProgress = true;
        this.uploadButtonText = 'Uploading...'
        this.bulkUploadService.openOptionsDialog(spots);
    }
    async onFileDropped(files: FileList) {
        if(this.dropped) {
            // Stop accepting files if they have already dropped some.
            return false;
        }

        // Display the validation message
        this.checking = true;

        // Let's always clear out the spot metadata, just in case
        this.spotsToCreate = {};

        let next;
        let i = 0;
        let extension = /\.[^.]*$/;
        let specialCharacters = /[^a-zA-Z0-9]/g;

        // Collect only the first 10 files supplied, because that is our limit
        while (next = files[i++]) {
            // Conform the ISCIs generated to our standards (all caps, no symbols)
            let convertedIsci: string = next.name.replace(extension, '').replace(specialCharacters, '').toUpperCase();
            // Create a key that won't conflict if multiple files with the same name are selected
            let convertedKey: string = i + convertedIsci;

            // If this account has strict ISCI enabled, we can't let more than 15 characters through
            if(this.strictIsci) {
                convertedIsci = convertedIsci.substring(0, 15);
            }

            this.spotsToCreate[convertedKey] = {
                isci: convertedIsci,
                file: next,
                invalid: null,
                isExisting: false,
                status: null,
                invalidFileFormat: false,
                invalidFileName:false
            };

            this.constructedFormGroup[convertedKey] = new FormGroup({
                isci: new FormControl(convertedIsci, Validators.required),
                title: new FormControl(''),
                format: new FormControl('HD', Validators.required), // If you change this to something other than HD, update it above in the invalid check
                agency: new FormControl(''),
                advertiser: new FormControl(''),
                brand: new FormControl(''),
                file: new FormControl(next),
                id: new FormControl('')
            });
            //Keeping really old date , so it can fetch any expired spots
            const createdAndUpdatedDateFrom = "01/01/1947";
            var dateCreatedAndUpdated =
                'gte:' +
                moment(new Date(createdAndUpdatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            this.spotResource.getProviderSpotsWithError({ isci: convertedIsci, createdDateFrom: dateCreatedAndUpdated, dateUpdated: dateCreatedAndUpdated }).subscribe( (result) => {
                // Set the invalid reason based on the spot's possible conflicts in the system
                this.spotsToCreate[convertedKey].isExisting = false;
                this.spotsToCreate[convertedKey].hasMedia = false;
                if (result && result.count > 0) {
                    this.spotsToCreate[convertedKey].invalid = null;
                    this.validateISCI(result, convertedKey, convertedIsci);
                } 
            });
            // Setup the isci check observables
            this.constructedFormGroup[convertedKey].controls.isci.valueChanges
            .pipe(
                // Don't fire the request right away, make sure the user has stopped typing
                debounceTime(300),
                switchMap(value => {
                    if (value && typeof value === 'string') {
                        this.isciChange = value;
                        this.spotsToCreate[convertedKey].isci = value;
                        return this.spotResource.getProviderSpotsWithError({isci: (value as string).toUpperCase(), createdDateFrom: dateCreatedAndUpdated, dateUpdated: dateCreatedAndUpdated })
                    } else {
                        this.resetISCIForm(convertedKey);
                        this.spotsToCreate[convertedKey].invalid = 'An ISCI is required to begin upload';
                        return [];
                    }
                })
            ).subscribe((res:any) => {
                this.spotsToCreate[convertedKey].invalid = null;
                this.isciChecks();
                this.resetISCIForm(convertedKey);
                if(res && res.count > 0) {
                    this.validateISCI(res, convertedKey, this.isciChange);
                } else {
                    this.spotsToCreate[convertedKey].invalid = this.spotsToCreate[convertedKey].invalid;
                }
            });
            this.constructedFormGroup[convertedKey].controls.format.valueChanges
            .subscribe((format: string) => {
                if(this.spotsToCreate[convertedKey].invalid != 'File Processing - Cannot Replace Media at this time' && this.spotsToCreate[convertedKey].invalid != 'Spot has already been sent - cannot replace, Please remove.'
                ) { 
                    this.spotsToCreate[convertedKey].invalid = null;
                    this.isciChecks();
                }
            });
                //To check the uploaded spot file format
                this.checkFileFormat(next.name,convertedKey)

                //To check the uploaded spot file Name
                this.checkFileName(next.name,convertedKey)
                
                // Setup the filter observables for a/a/b
                this.constructedFormGroup[convertedKey].controls.agency.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        if (value && typeof value === 'string') {
                            // Remove the Advertiser
                            this.constructedFormGroup[convertedKey].controls.advertiser.setValue('');
                            // Clear autocomplete options
                            this.filteredAdvertisers[convertedKey] = [];
                            return this.agencyResource.getAll({name: value, limit: 15, activeOnly: true});
                        } else {
                            const advertiser = this.constructedFormGroup[convertedKey].controls.advertiser.value;
                            if(!value && advertiser){
                                this.constructedFormGroup[convertedKey].controls.advertiser.setValue('');
                                this.constructedFormGroup[convertedKey].controls.brand.setValue('');
                            }
                            return [];
                        }
                    })
                ).subscribe((agencies:any) => {
                    this.filteredAgencies[convertedKey] = agencies.rows ?? [];
                });

                this.constructedFormGroup[convertedKey].controls.advertiser.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        let agencyId = -1;
                        if (this.constructedFormGroup[convertedKey].controls.agency.value && this.constructedFormGroup[convertedKey].controls.agency.value.id) {
                            agencyId = this.constructedFormGroup[convertedKey].controls.agency.value.id
                        }                        
                        if (value && typeof value === 'string') {
                            // Remove the Advertiser
                            this.constructedFormGroup[convertedKey].controls.brand.setValue('');
                            // Clear autocomplete options
                            this.filteredBrands[convertedKey] = [];
                            return this.advertiserResource.getAll({
                                name: value,
                                limit: 15,
                                active: true,
                                agencyId
                            });
                        } else {
                            const brand = this.constructedFormGroup[convertedKey].controls.brand.value
                            if(!value && brand){
                                this.constructedFormGroup[convertedKey].controls.brand.setValue('');
                            }
                            return [];
                        }
                    })
                ).subscribe((advertisers:any) => {
                    this.filteredAdvertisers[convertedKey] = advertisers ?? [];
                });

                this.constructedFormGroup[convertedKey].controls.brand.valueChanges
                .pipe(
                    // Don't fire the request right away, make sure the user has stopped typing
                    debounceTime(300),
                    // Don't re-request data if the user has only selected an option
                    switchMap(value => {
                        let advertiserId = 0;
                        if (this.constructedFormGroup[convertedKey].controls.advertiser.value && this.constructedFormGroup[convertedKey].controls.advertiser.value.id) {
                            advertiserId = this.constructedFormGroup[convertedKey].controls.advertiser.value.id
                        }

                        if (value && typeof value === 'string') {
                            return this.brandResource.getAll({
                                name: value,
                                limit: 15,
                                active: true,
                                advertiserId
                            });
                        } else {
                            return [];
                        }
                    })
                ).subscribe((brands:any) => {
                    this.filteredBrands[convertedKey] = brands ?? [];
                });

            if(i === 25) {
                break;
            }
        }

        // Dynamically generate the form
        this.newSpotRows = new FormGroup(this.constructedFormGroup);

        this.isciChecks();

        this.checking = false;
        this.dropped = true;

        // display screen to populate meta (
        //     ISCI - required
        //     Format
        //     Title
        //     Agency
        //     Advertiser
        //     Brand
        // )
    }
    //reset data on isci change
    resetISCIForm(convertedKey: any) {
        this.constructedFormGroup[convertedKey].controls.agency.setValue('');
        this.constructedFormGroup[convertedKey].controls.advertiser.setValue('');
        this.constructedFormGroup[convertedKey].controls.brand.setValue('');
        this.constructedFormGroup[convertedKey].controls.title.setValue('');
        this.constructedFormGroup[convertedKey].controls.format.setValue('HD');
        this.spotsToCreate[convertedKey].status = null;
        this.spotsToCreate[convertedKey].isExisting = false;
    }
    getMatchingISCI (spots: any, originalISCI: string) {
        let matchingIsci: any = {};
        if (spots.count > 0) {
            for( let i = 0; i < spots.count; i++){
                if(spots.rows[i].isci === originalISCI) {
                    matchingIsci = spots.rows[i];
                    break;
                }
            }
        }
        return matchingIsci;
    }
    //validate ISCI existing or not to show respective errors
    validateISCI(result: any, convertedKey: any, originalISCI: any) {
        let matchingIsci: any = this.getMatchingISCI(result, originalISCI);
        if (JSON.stringify(matchingIsci) != '{}') {
            const currentSpot = matchingIsci;
            this.spotsToCreate[convertedKey].status = this.displayStatus(currentSpot.status);
            this.spotsToCreate[convertedKey].isci = currentSpot.isci;
            if (currentSpot.title) {
                this.constructedFormGroup[convertedKey].controls.title.setValue(currentSpot.title);
            }
            if (currentSpot.format) {
                this.constructedFormGroup[convertedKey].controls.format.setValue(currentSpot.format);
            }
            if (currentSpot.Agency && currentSpot.Agency.name) {
                this.constructedFormGroup[convertedKey].controls.agency.setValue(currentSpot.Agency);
            }
            if (currentSpot.Advertiser && currentSpot.Advertiser.name) {
                this.constructedFormGroup[convertedKey].controls.advertiser.setValue(currentSpot.Advertiser);
            }
            if (currentSpot.Brand && currentSpot.Brand.name) {
                this.constructedFormGroup[convertedKey].controls.brand.setValue(currentSpot.Brand);
            }
            if (currentSpot.id) {
                this.constructedFormGroup[convertedKey].controls.id.setValue(currentSpot.id);
            }
            if (currentSpot.originalAssetId || currentSpot.distributionMasterId) {
                this.spotsToCreate[convertedKey].hasMedia = true;
            }
            //checking the status of the existing isci
            if (matchingIsci.status === 'VERIFYING' || matchingIsci.status === 'PROCESSING' || matchingIsci.status === 'UPLOADED' || matchingIsci.status === 'UPLOADING' || this.sessionData.account.accountType.replace('_', '') === 'QCVENDOR' || (!this.hasPermission(['spot.modify']) && this.sessionData.accountType !== 'PRODUCTIONSERVICESVENDOR')) {
                this.spotsToCreate[convertedKey].invalid = 'File Processing - Cannot Replace Media at this time.';
                this.spotsToCreate[convertedKey].hasMedia = false;
            } else {
                //checking if meta data of an isci can be updated along with media.
                const isEditable = this.metadataEditable(currentSpot);
                if(isEditable) {
                    this.spotsToCreate[convertedKey].isExisting = true;
                } else {
                    this.spotsToCreate[convertedKey].invalid = 'Spot has already been sent - cannot replace, Please remove.';
                    this.spotsToCreate[convertedKey].hasMedia = false;
                } 
            }
        }
    }
    //check for meta data is editable or media is uploaded
    metadataEditable(spot: any) {
        // Status is in an editable state
        let editableStatus =
            [
                'DRAFT',
                'PENDING_MEDIA',
                'PENDING_META',
                'PENDING_QC',
                'PENDING_PROD_SVCS',
                'REJECTED',
                'CANCELLED',
                'UNSENT',
            ].indexOf(spot.status) > -1;

            this.isFriend = this.sessionData.accountId != spot.ProviderAccount.id;
        // User is the owner OR a friend OR the spot is new, which means you're the owner (the `==` is on purpose, because sometimes ids are strings
        let ownerOrFriend =
            (spot.ProviderAccount &&
                this.sessionData.accountId == spot.ProviderAccount.id) ||
            this.isFriend 

        return editableStatus && ownerOrFriend;
    }
    //get display status
    displayStatus(status: string) {
        let displayStatus : string = '';
        switch(status) {
            case 'PENDING_MEDIA':
                displayStatus = 'Pending Media'
                break;
            case 'UPLOADING':
                displayStatus = 'Uploading'
                break;
            case 'UPLOADED':
                displayStatus = 'Uploaded'
                break;
            case 'CANCELLED':
                displayStatus = 'Cancelled'
                break;
            case 'VERIFYING':
                displayStatus = 'Verifying'
                break;
            case 'PROCESSING':
                displayStatus = 'Processing'
                break;
            case 'REJECTED':
                displayStatus = 'Rejected'
                break;
            case 'PENDING_QC':
                displayStatus = 'Pending QC'
                break;
            case 'PENDING_PROD_SVCS':
                displayStatus = 'Pending Prod SVCS'
                break;
            case 'PENDING_META':
                displayStatus = 'Pending Metadata'
                break;
            case 'UNSENT':
                displayStatus = 'Unsent'
                break;
            case 'SENDING':
                displayStatus = 'Sending'
                break;
            case 'SENT':
                displayStatus = 'Sent'
                break;
            default:
                displayStatus = ''
                break;
        }
        return displayStatus;
    }
    handleAgencySelect(event:any, isci:any) {
        this.newSpotRows.controls[isci].get('advertiser')?.setValue('');
        this.filteredAdvertisers[isci] = [];
    }

    blurAgency(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('agency')?.value === 'string' || !this.newSpotRows.controls[isci].get('agency')?.value.id) {
            this.newSpotRows.controls[isci].get('agency')?.setValue('');
        }
    }

    handleAdvertiserSelect(event:any, isci:any) {
        this.newSpotRows.controls[isci].get('brand')?.setValue('');
        this.filteredBrands[isci] = [];
    }

    blurAdvertiser(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('advertiser')?.value === 'string' || !this.newSpotRows.controls[isci].get('advertiser')?.value.id) {
            this.newSpotRows.controls[isci].get('advertiser')?.setValue('');
        }
    }

    blurBrand(event:any, isci:any) {
        if (typeof this.newSpotRows.controls[isci].get('brand')?.value === 'string' || !this.newSpotRows.controls[isci].get('brand')?.value.id) {
            this.newSpotRows.controls[isci].get('brand')?.setValue('');
        }
    }

    isciChecks() {
        // Store values to track for duplicate values
        let doubles: {[index: string]: string[]} = {}; // Store the ISCIs as keys, with lists of matching keys
        let extras: string[] = [];
        let specialCharacters = /[^a-zA-Z0-9]/g;

        for (const [key, value] of Object.entries(this.newSpotRows.value)) {
            // if this spot
            if(this.spotsToCreate[key].invalid === 'File Processing - Cannot Replace Media at this time.') {
                continue;
            }
            if(this.spotsToCreate[key].invalid === 'Spot has already been sent - cannot replace, Please remove.') {
                continue;
            }

            // Clear out invalid reasons so we can reevaluate
            this.spotsToCreate[key].invalid = null;

            // Cast the "unknown" value object into something we can interact with properly
            let temp: SpotMetadata = value as SpotMetadata;

            // Checks specifically for strict ISCI accounts
            if(this.strictIsci) {
                if (!temp.isci || temp.isci.length < 1) {
                    this.spotsToCreate[key].invalid = "ISCI is required";
                }

                switch(this.newSpotRows.controls[key].get('format')?.value) {
                    case 'SD':
                    case 'RADIO':
                    case 'COMPOSITE':
                        if (temp.isci.match(/[Hh]$/)) {
                            this.spotsToCreate[key].invalid = "Non-HD spots must not end in 'H'";
                        }
                        break;
                    case 'HD':
                        if (!temp.isci.match(/[Hh]$/)) {
                            this.spotsToCreate[key].invalid = "HD spots must end in 'H'";
                        }
                        break;
                }

                if (temp.isci.length > 15) {
                    this.spotsToCreate[key].invalid = "This ISCI is longer than the allowed limit";
                }
            }

            if (temp.isci.length < 1) {
                this.spotsToCreate[key].invalid = "An ISCI is required to begin upload";
            }

            if (temp.isci.match(specialCharacters)) {
                this.spotsToCreate[key].invalid = "ISCI can only contain letters and numbers";
            }

            if (!doubles[temp.isci]) {
                doubles[temp.isci] = [key];
            } else {
                doubles[temp.isci].push(key);
                extras.push(temp.isci);
            }
        }

        // Override any invalid notes with the most important one: they have used an ISCI twice
        for (let i = 0; i < extras.length; i++) {
            for (let x = 0; x < doubles[extras[i]].length; x++) {
                this.spotsToCreate[doubles[extras[i]][x]].invalid = "You've used this ISCI too many times";
            }
        }
    }

    // @ts-ignore: Not Really unused, because it's in the template
    private _objectName(obj: any) {
        return obj && obj.name ? obj.name : '';
    }

    isValid() {
        let valid = true;

        for (const [, value] of Object.entries(this.spotsToCreate)) {
            if (value.invalid || value.invalidFileFormat || value.invalidFileName) {
                valid = false;
                break;
            }
        }
        return valid;
    }

    //Method to remove a spot before uploading
    removeUploadingSpot(spot: any) {
        Object.keys(this.spotsToCreate).forEach(eISCI => {
            if(spot.key === eISCI) {
                this.newSpotRows.removeControl(eISCI);
                delete this.spotsToCreate[eISCI];
                this.isciChecks();
            }
        });
        //close the upload modal if there are no spots to upload.
        if(Object.keys(this.spotsToCreate).length === 0) {
            this.dialogRef.close();
        }
    }

    checkFileFormat(filename: string, convertedKey: string) {
        let invalidFileList = [
            "exe", "bat", "sh", "cmd", "com", "scr", "msi", "reg", "app", "apk", "ipa", 
            "deb", "bin", "iso", "dmg", "torrent", "ace", "arj", "arc"
        ]; 
            let uploadArray = filename.split('.');
            let fileExtension = uploadArray[uploadArray.length - 1];
            if (invalidFileList.indexOf(fileExtension.toLowerCase()) > -1) {
                this.spotsToCreate[convertedKey].invalidFileFormat = true;
            }else {
                this.spotsToCreate[convertedKey].invalidFileFormat = false;
            }
    }

    checkFileName(filename: string, convertedKey: string) {
        const specialCharPattern = /[<>"=]/;
        let uploadArray = filename.split('.');
        if (specialCharPattern.test(uploadArray[0])) {
            this.spotsToCreate[convertedKey].invalidFileName = true;
        }else {
            this.spotsToCreate[convertedKey].invalidFileName = false;
        }
    }
}

angular.module("comcast.directives").directive(
    'ctsBulkUploadDialog',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsBulkUploadDialog, inputs: [], outputs: []}));